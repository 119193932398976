import './App.scss';
import data from './data/main.json';
import ChooseModelsCards from './components/organisms/cards/choose-models';

function App() {
  return (
    <main>
      <section>
        <div className='container'>
          <ChooseModelsCards {...data.chooseModels}/>
        </div>
      </section>
    </main>
  );
}

export default App;
