import React from 'react';
import "./index.scss";
import Parser from 'html-react-parser';

const ChooseModelsCard = ({
  className,
  title,
  image,
  alt,
  link
}) => {
  return (
    <div className={`choose-models-card ${className || ''}`}>
      <a className='choose-models-card__border-wrapper' href={link} rel="noreferrer noopener">
        {(typeof title === 'string' || typeof image === 'string') && (
          <div className='choose-models-card__wrapper'>
            {title && <div className='choose-models-card__title'>{Parser(title)}</div>}
            {image && (
              <img className='choose-models-card__image' src={image} alt={`${alt ? alt : "car"}`} />
            )}
          </div>
        )}
        <div className='choose-models-card__link'>
          <img src='assets/svg/arrow.svg' alt='arrow'/>
        </div>
      </a>
    </div>
  );
}

export default ChooseModelsCard;
