import React from 'react';
import "./index.scss";
import Parser from 'html-react-parser';
import ChooseModelsCard from '../../../molecules/card/choose-models';

const ChooseModelsCards = ({
  className,
  title,
  cards
}) => {
  return (
    <div className={`choose-models-cards ${className || ''}`}>
      <img className='choose-models-cards__logo' src='assets/svg/logo-KIA.svg' alt='logo' />
      {Array.isArray(cards) && (
        <div className='choose-models-cards__cards'>
          {cards.map((card, index) => {
            return (
              <ChooseModelsCard key={`card-${index}`} className={`choose-models-cards__card`} {...card} />
            )
          })}
        </div>
      )}
      {typeof title === 'string' && <div className="choose-models-cards__title">{Parser(title)}</div>}
    </div>
  );
}

export default ChooseModelsCards;
